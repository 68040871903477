export const DEFAULT_PRECISION = 2;
export const DEFAULT_PRECISION_TEXT = '1.2-2';

export class CurrencyModel{
    precision : number;
    code :string; 
    label : string; 
    show : boolean; 
    symbol : string;
    showOnlyIfMatchesSecondaryCurrency?: boolean;
}
export const currencies : CurrencyModel[] = [
    {precision : 2, show : true, code : "USD",	label : "CURRENCY.USD", symbol : "USD"},
    {precision : 2, show : true, code : "EUR",	label : "CURRENCY.EUR", symbol : "€"},
    {precision : 2, show : true, code : "ARS",	label : "CURRENCY.ARS", symbol : "$"},
    {precision : 2, show : true, code : "BOB",	label : "CURRENCY.BOB", symbol : "Bs."},
    {precision : 2, show : true, code : "UYU",	label : "CURRENCY.UYU", symbol : "$"},
    {precision : 2, show : true, code : "BRL",	label : "CURRENCY.BRL", symbol : "R$"},
    {precision : 0, show : true, code : "PYG",	label : "CURRENCY.PYG", symbol : "₲"},
    {precision : 2, show : true, code : "CAD",	label : "CURRENCY.CAD", symbol : "$"},
    {precision : 2, show : true, code : "MXN",	label : "CURRENCY.MXN", symbol : "$"},
    {precision : 0, show : true, code : "COP",	label : "CURRENCY.COP", symbol : "$"},
    {precision : 0, show : true, code : "CRC",	label : "CURRENCY.CRC", symbol : "₡"},
    {precision : 2, show : true, code : "PEN", label : "CURRENCY.PEN", symbol : "S/"},
    {precision : 2, show : true, code : "CUP",	label : "CURRENCY.CUP", symbol : "$"},
    {precision : 2, show : true, code : "VES",	label : "CURRENCY.VES", symbol : "Bs."},
    {precision : 2, show : true, code : "HNL",	label : "CURRENCY.HNL", symbol : "L"},
    {precision : 2, show : true, code : "PAB",	label : "CURRENCY.PAB", symbol : "B/"},
    {precision : 2, show : true, code : "NIO",	label : "CURRENCY.NIO", symbol : "C$"},
    {precision : 2, show : true, code : "DOP",	label : "CURRENCY.DOP", symbol : "$"},
    {precision : 2, show : true, code : "HTG",	label : "CURRENCY.HTG", symbol : "G"},
    {precision : 2, show : true, code : "JMD",	label : "CURRENCY.JMD", symbol : "$"},
    {precision : 2, show : true, code : "BZD",	label : "CURRENCY.BZD", symbol : "$"},
    {precision : 2, show : true, code : "SRD",	label : "CURRENCY.SRD", symbol : "$"},
    {precision : 2, show : true, code : "XCD",	label : "CURRENCY.XCD", symbol : "$"},
    {precision : 2, show : true, code : "BSD",	label : "CURRENCY.BSD", symbol : "$"},
    {precision : 2, show : true, code : "BBD",	label : "CURRENCY.BBD", symbol : "$"},
    {precision : 2, show : true, code : "AWG",	label : "CURRENCY.AWG", symbol : "ƒ"},
    {precision : 2, show : true, code : "ANG",	label : "CURRENCY.ANG", symbol : "ƒ"},
    {precision : 2, show : true, code : "TTD",	label : "CURRENCY.TTD", symbol : "$"},
    {precision : 0, show : true, code : "CLP",	label : "CURRENCY.CLP", symbol : "$"},
    {precision : 2, show : true, code : "GTQ",	label : "CURRENCY.GTQ", symbol : "Q"},
    {precision : 2, show : true, code : "SVC",	label : "CURRENCY.SVC", symbol : "₡"},
    {precision : 2, show : true, code : "CLF",	label : "CURRENCY.CLF", symbol : "UF", showOnlyIfMatchesSecondaryCurrency : true},
]


/* export const currencies : {code :string; label : string, show : boolean, symbol : string}[] = [
    {show : true, code : "ADP",	label : "CURRENCY.ADP", symbol : ""},
    {show : true, code : "AED",	label : "CURRENCY.AED", symbol : ""},
    {show : true, code : "AFA",	label : "CURRENCY.AFA", symbol : ""},
    {show : true, code : "AFN",	label : "CURRENCY.AFN", symbol : "؋"},
    {show : true, code : "ALK",	label : "CURRENCY.ALK", symbol : ""},
    {show : true, code : "ALL",	label : "CURRENCY.ALL", symbol : "Lek"},
    {show : true, code : "AMD",	label : "CURRENCY.AMD", symbol : "Դ"},
    {show : true, code : "ANG",	label : "CURRENCY.ANG", symbol : ""},
    {show : true, code : "AOA",	label : "CURRENCY.AOA", symbol : "is"},
    {show : true, code : "AOK",	label : "CURRENCY.AOK", symbol : ""},
    {show : true, code : "AON",	label : "CURRENCY.AON", symbol : ""},
    {show : true, code : "AOR",	label : "CURRENCY.AOR", symbol : ""},
    {show : true, code : "ARA",	label : "CURRENCY.ARA", symbol : ""},
    {show : true, code : "ARL",	label : "CURRENCY.ARL", symbol : ""},
    {show : true, code : "ARM",	label : "CURRENCY.ARM", symbol : ""},
    {show : true, code : "ARP",	label : "CURRENCY.ARP", symbol : ""},
    {show : true, code : "ARS",	label : "CURRENCY.ARS", symbol : "$"},
    {show : true, code : "ATS",	label : "CURRENCY.ATS", symbol : ""},
    {show : true, code : "AUD",	label : "CURRENCY.AUD", symbol : "$"},
    {show : true, code : "AWG",	label : "CURRENCY.AWG", symbol : "ƒ"},
    {show : true, code : "AZM",	label : "CURRENCY.AZM", symbol : ""},
    {show : true, code : "AZN",	label : "CURRENCY.AZN", symbol : "₼"},
    {show : true, code : "BAD",	label : "CURRENCY.BAD", symbol : ""},
    {show : true, code : "BAM",	label : "CURRENCY.BAM", symbol : "КМ"},
    {show : true, code : "BAN",	label : "CURRENCY.BAN", symbol : ""},
    {show : true, code : "BBD",	label : "CURRENCY.BBD", symbol : "$"},
    {show : true, code : "BDT",	label : "CURRENCY.BDT", symbol : "৳"},
    {show : true, code : "BEC",	label : "CURRENCY.BEC", symbol : ""},
    {show : true, code : "BEF",	label : "CURRENCY.BEF", symbol : ""},
    {show : true, code : "BEL",	label : "CURRENCY.BEL", symbol : ""},
    {show : true, code : "BGL",	label : "CURRENCY.BGL", symbol : ""},
    {show : true, code : "BGM",	label : "CURRENCY.BGM", symbol : ""},
    {show : true, code : "BGN",	label : "CURRENCY.BGN", symbol : "лв"},
    {show : true, code : "BGO",	label : "CURRENCY.BGO", symbol : ""},
    {show : true, code : "BHD",	label : "CURRENCY.BHD", symbol : "ب.د"},
    {show : true, code : "BIF",	label : "CURRENCY.BIF", symbol : "₣"},
    {show : true, code : "BMD",	label : "CURRENCY.BMD", symbol : "$"},
    {show : true, code : "BND",	label : "CURRENCY.BND", symbol : "$"},
    {show : true, code : "BOB",	label : "CURRENCY.BOB", symbol : "Bs."},
    {show : true, code : "BOL",	label : "CURRENCY.BOL", symbol : ""},
    {show : true, code : "BOP",	label : "CURRENCY.BOP", symbol : ""},
    {show : true, code : "BOV",	label : "CURRENCY.BOV", symbol : "Mvdol"},
    {show : true, code : "BRB",	label : "CURRENCY.BRB", symbol : ""},
    {show : true, code : "BRC",	label : "CURRENCY.BRC", symbol : ""},
    {show : true, code : "BRE",	label : "CURRENCY.BRE", symbol : ""},
    {show : true, code : "BRL",	label : "CURRENCY.BRL", symbol : "R$"},
    {show : true, code : "BRN",	label : "CURRENCY.BRN", symbol : ""},
    {show : true, code : "BRR",	label : "CURRENCY.BRR", symbol : ""},
    {show : true, code : "BRZ",	label : "CURRENCY.BRZ", symbol : ""},
    {show : true, code : "BSD",	label : "CURRENCY.BSD", symbol : "$"},
    {show : true, code : "BTN",	label : "CURRENCY.BTN", symbol : ""},
    {show : true, code : "BUK",	label : "CURRENCY.BUK", symbol : ""},
    {show : true, code : "BWP",	label : "CURRENCY.BWP", symbol : "P"},
    {show : true, code : "BYB",	label : "CURRENCY.BYB", symbol : ""},
    {show : true, code : "BYN",	label : "CURRENCY.BYN", symbol : "p."},
    {show : true, code : "BYR",	label : "CURRENCY.BYR", symbol : ""},
    {show : true, code : "BZD",	label : "CURRENCY.BZD", symbol : "$"},
    {show : true, code : "CAD",	label : "CURRENCY.CAD", symbol : "$"},
    {show : true, code : "CDF",	label : "CURRENCY.CDF", symbol : "₣"},
    {show : true, code : "CHE",	label : "CURRENCY.CHE", symbol : ""},
    {show : true, code : "CHF",	label : "CURRENCY.CHF", symbol : "₣"},
    {show : true, code : "CHW",	label : "CURRENCY.CHW", symbol : ""},
    {show : true, code : "CLE",	label : "CURRENCY.CLE", symbol : ""},
    {show : true, code : "CLF",	label : "CURRENCY.CLF", symbol : ""},
    {show : true, code : "CLP",	label : "CURRENCY.CLP", symbol : "$"},
    {show : true, code : "CNH",	label : "CURRENCY.CNH", symbol : ""},
    {show : true, code : "CNX",	label : "CURRENCY.CNX", symbol : ""},
    {show : true, code : "CNY",	label : "CURRENCY.CNY", symbol : "¥"},
    {show : true, code : "COP",	label : "CURRENCY.COP", symbol : "$"},
    {show : true, code : "COU",	label : "CURRENCY.COU", symbol : ""},
    {show : true, code : "CRC",	label : "CURRENCY.CRC", symbol : "₡"},
    {show : true, code : "CSD",	label : "CURRENCY.CSD", symbol : ""},
    {show : true, code : "CSK",	label : "CURRENCY.CSK", symbol : ""},
    {show : true, code : "CUC",	label : "CURRENCY.CUC", symbol : ""},
    {show : true, code : "CUP",	label : "CURRENCY.CUP", symbol : ""},
    {show : true, code : "CVE",	label : "CURRENCY.CVE", symbol : ""},
    {show : true, code : "CYP",	label : "CURRENCY.CYP", symbol : ""},
    {show : true, code : "CZK",	label : "CURRENCY.CZK", symbol : "Kč"},
    {show : true, code : "DDM",	label : "CURRENCY.DDM", symbol : ""},
    {show : true, code : "DEM",	label : "CURRENCY.DEM", symbol : ""},
    {show : true, code : "DJF",	label : "CURRENCY.DJF", symbol : "₣"},
    {show : true, code : "DKK",	label : "CURRENCY.DKK", symbol : "kr"},
    {show : true, code : "DOP",	label : "CURRENCY.DOP", symbol : "$"},
    {show : true, code : "DZD",	label : "CURRENCY.DZD", symbol : "د.ج"},
    {show : true, code : "ECS",	label : "CURRENCY.ECS", symbol : ""},
    {show : true, code : "ECV",	label : "CURRENCY.ECV", symbol : ""},
    {show : true, code : "EEK",	label : "CURRENCY.EEK", symbol : ""},
    {show : true, code : "EGP",	label : "CURRENCY.EGP", symbol : "£"},
    {show : true, code : "ERN",	label : "CURRENCY.ERN", symbol : "Nfk"},
    {show : true, code : "ESA",	label : "CURRENCY.ESA", symbol : ""},
    {show : true, code : "ESB",	label : "CURRENCY.ESB", symbol : ""},
    {show : true, code : "ESP",	label : "CURRENCY.ESP", symbol : ""},
    {show : true, code : "ETB",	label : "CURRENCY.ETB", symbol : ""},
    {show : true, code : "EUR",	label : "CURRENCY.EUR", symbol : "€"},
    {show : true, code : "FIM",	label : "CURRENCY.FIM", symbol : ""},
    {show : true, code : "FJD",	label : "CURRENCY.FJD", symbol : "$"},
    {show : true, code : "FKP",	label : "CURRENCY.FKP", symbol : "£"},
    {show : true, code : "FRF",	label : "CURRENCY.FRF", symbol : ""},
    {show : true, code : "GBP",	label : "CURRENCY.GBP", symbol : "£"},
    {show : true, code : "GEK",	label : "CURRENCY.GEK", symbol : ""},
    {show : true, code : "GEL",	label : "CURRENCY.GEL", symbol : "ლ"},
    {show : true, code : "GHC",	label : "CURRENCY.GHC", symbol : ""},
    {show : true, code : "GHS",	label : "CURRENCY.GHS", symbol : "₵"},
    {show : true, code : "GIP",	label : "CURRENCY.GIP", symbol : "£"},
    {show : true, code : "GMD",	label : "CURRENCY.GMD", symbol : "D"},
    {show : true, code : "GNF",	label : "CURRENCY.GNF", symbol : "₣"},
    {show : true, code : "GNS",	label : "CURRENCY.GNS", symbol : ""},
    {show : true, code : "GQE",	label : "CURRENCY.GQE", symbol : ""},
    {show : true, code : "GRD",	label : "CURRENCY.GRD", symbol : ""},
    {show : true, code : "GTQ",	label : "CURRENCY.GTQ", symbol : "Q"},
    {show : true, code : "GWE",	label : "CURRENCY.GWE", symbol : ""},
    {show : true, code : "GWP",	label : "CURRENCY.GWP", symbol : ""},
    {show : true, code : "GYD",	label : "CURRENCY.GYD", symbol : "$"},
    {show : true, code : "HKD",	label : "CURRENCY.HKD", symbol : "$"},
    {show : true, code : "HNL",	label : "CURRENCY.HNL", symbol : "L"},
    {show : true, code : "HRD",	label : "CURRENCY.HRD", symbol : ""},
    {show : true, code : "HRK",	label : "CURRENCY.HRK", symbol : "Kn"},
    {show : true, code : "HTG",	label : "CURRENCY.HTG", symbol : "G"},
    {show : true, code : "HUF",	label : "CURRENCY.HUF", symbol : "Ft"},
    {show : true, code : "IDR",	label : "CURRENCY.IDR", symbol : "Rp"},
    {show : true, code : "IEP",	label : "CURRENCY.IEP", symbol : ""},
    {show : true, code : "ILP",	label : "CURRENCY.ILP", symbol : ""},
    {show : true, code : "ILR",	label : "CURRENCY.ILR", symbol : ""},
    {show : true, code : "ILS",	label : "CURRENCY.ILS", symbol : "₪"},
    {show : true, code : "INR",	label : "CURRENCY.INR", symbol : "₨"},
    {show : true, code : "IQD",	label : "CURRENCY.IQD", symbol : "ع.د"},
    {show : true, code : "IRR",	label : "CURRENCY.IRR", symbol : "﷼"},
    {show : true, code : "ISJ",	label : "CURRENCY.ISJ", symbol : ""},
    {show : true, code : "ISK",	label : "CURRENCY.ISK", symbol : "Kr"},
    {show : true, code : "ITL",	label : "CURRENCY.ITL", symbol : ""},
    {show : true, code : "JMD",	label : "CURRENCY.JMD", symbol : "$"},
    {show : true, code : "JOD",	label : "CURRENCY.JOD", symbol : "د.ا"},
    {show : true, code : "JPY",	label : "CURRENCY.JPY", symbol : "¥"},
    {show : true, code : "KES",	label : "CURRENCY.KES", symbol : "Sh"},
    {show : true, code : "KGS",	label : "CURRENCY.KGS", symbol : ""},
    {show : true, code : "KHR",	label : "CURRENCY.KHR", symbol : "៛"},
    {show : true, code : "KMF",	label : "CURRENCY.KMF", symbol : ""},
    {show : true, code : "KPW",	label : "CURRENCY.KPW", symbol : ""},
    {show : true, code : "KRH",	label : "CURRENCY.KRH", symbol : ""},
    {show : true, code : "KRO",	label : "CURRENCY.KRO", symbol : ""},
    {show : true, code : "KRW",	label : "CURRENCY.KRW", symbol : ""},
    {show : true, code : "KWD",	label : "CURRENCY.KWD", symbol : "د.ك"},
    {show : true, code : "KYD",	label : "CURRENCY.KYD", symbol : "$"},
    {show : true, code : "KZT",	label : "CURRENCY.KZT", symbol : "〒"},
    {show : true, code : "LAK",	label : "CURRENCY.LAK", symbol : ""},
    {show : true, code : "LBP",	label : "CURRENCY.LBP", symbol : "ل.ل"},
    {show : true, code : "LKR",	label : "CURRENCY.LKR", symbol : "Rs"},
    {show : true, code : "LRD",	label : "CURRENCY.LRD", symbol : "$"},
    {show : true, code : "LSL",	label : "CURRENCY.LSL", symbol : "L"},
    {show : true, code : "LTL",	label : "CURRENCY.LTL", symbol : ""},
    {show : true, code : "LTT",	label : "CURRENCY.LTT", symbol : ""},
    {show : true, code : "LUC",	label : "CURRENCY.LUC", symbol : ""},
    {show : true, code : "LUF",	label : "CURRENCY.LUF", symbol : ""},
    {show : true, code : "LUL",	label : "CURRENCY.LUL", symbol : ""},
    {show : true, code : "LVL",	label : "CURRENCY.LVL", symbol : ""},
    {show : true, code : "LVR",	label : "CURRENCY.LVR", symbol : ""},
    {show : true, code : "LYD",	label : "CURRENCY.LYD", symbol : "ل.د"},
    {show : true, code : "MAD",	label : "CURRENCY.MAD", symbol : "د.م."},
    {show : true, code : "MAF",	label : "CURRENCY.MAF", symbol : ""},
    {show : true, code : "MCF",	label : "CURRENCY.MCF", symbol : ""},
    {show : true, code : "MDC",	label : "CURRENCY.MDC", symbol : ""},
    {show : true, code : "MDL",	label : "CURRENCY.MDL", symbol : "L"},
    {show : true, code : "MGA",	label : "CURRENCY.MGA", symbol : "MK"},
    {show : true, code : "MGF",	label : "CURRENCY.MGF", symbol : ""},
    {show : true, code : "MKD",	label : "CURRENCY.MKD", symbol : ""},
    {show : true, code : "MKN",	label : "CURRENCY.MKN", symbol : ""},
    {show : true, code : "MLF",	label : "CURRENCY.MLF", symbol : ""},
    {show : true, code : "MMK",	label : "CURRENCY.MMK", symbol : "K"},
    {show : true, code : "MNT",	label : "CURRENCY.MNT", symbol : "₮"},
    {show : true, code : "MOP",	label : "CURRENCY.MOP", symbol : "P"},
    {show : true, code : "MRO",	label : "CURRENCY.MRO", symbol : ""},
    {show : true, code : "MRU",	label : "CURRENCY.MRU", symbol : "UM"},
    {show : true, code : "MTL",	label : "CURRENCY.MTL", symbol : ""},
    {show : true, code : "MTP",	label : "CURRENCY.MTP", symbol : ""},
    {show : true, code : "MUR",	label : "CURRENCY.MUR", symbol : "₨"},
    {show : true, code : "MVP",	label : "CURRENCY.MVP", symbol : ""},
    {show : true, code : "MVR",	label : "CURRENCY.MVR", symbol : "ރ"},
    {show : true, code : "MWK",	label : "CURRENCY.MWK", symbol : ""},
    {show : true, code : "MXN",	label : "CURRENCY.MXN", symbol : "$"},
    {show : true, code : "MXP",	label : "CURRENCY.MXP", symbol : ""},
    {show : true, code : "MXV",	label : "CURRENCY.MXV", symbol : ""},
    {show : true, code : "MYR",	label : "CURRENCY.MYR", symbol : "RM"},
    {show : true, code : "MZE",	label : "CURRENCY.MZE", symbol : ""},
    {show : true, code : "MZM",	label : "CURRENCY.MZM", symbol : ""},
    {show : true, code : "MZN",	label : "CURRENCY.MZN", symbol : "MTn"},
    {show : true, code : "NAD",	label : "CURRENCY.NAD", symbol : "$"},
    {show : true, code : "NGN",	label : "CURRENCY.NGN", symbol : "₦"},
    {show : true, code : "NIC",	label : "CURRENCY.NIC", symbol : ""},
    {show : true, code : "NIO",	label : "CURRENCY.NIO", symbol : "C$"},
    {show : true, code : "NLG",	label : "CURRENCY.NLG", symbol : ""},
    {show : true, code : "NOK",	label : "CURRENCY.NOK", symbol : "kr"},
    {show : true, code : "NPR",	label : "CURRENCY.NPR", symbol : "₨"},
    {show : true, code : "NZD",	label : "CURRENCY.NZD", symbol : "$"},
    {show : true, code : "OMR",	label : "CURRENCY.OMR", symbol : ""},
    {show : true, code : "PAB",	label : "CURRENCY.PAB", symbol : "B/"},
    {show : true, code : "PEI",	label : "CURRENCY.PEI", symbol : ""},
    {show : true, code : "PEN", label : "CURRENCY.PEN", symbol : "S/"},
    {show : true, code : "PES",	label : "CURRENCY.PES", symbol : ""},
    {show : true, code : "PGK",	label : "CURRENCY.PGK", symbol : "K"},
    {show : true, code : "PHP",	label : "CURRENCY.PHP", symbol : "₱"},
    {show : true, code : "PKR",	label : "CURRENCY.PKR", symbol : "₨"},
    {show : true, code : "PLN",	label : "CURRENCY.PLN", symbol : "zł"},
    {show : true, code : "PLZ",	label : "CURRENCY.PLZ", symbol : ""},
    {show : true, code : "PTE",	label : "CURRENCY.PTE", symbol : ""},
    {show : true, code : "PYG",	label : "CURRENCY.PYG", symbol : "₲"},
    {show : true, code : "QAR",	label : "CURRENCY.QAR", symbol : "ر.ق"},
    {show : true, code : "RHD",	label : "CURRENCY.RHD", symbol : ""},
    {show : true, code : "ROL",	label : "CURRENCY.ROL", symbol : ""},
    {show : true, code : "RON",	label : "CURRENCY.RON", symbol : "L"},
    {show : true, code : "RSD",	label : "CURRENCY.RSD", symbol : "din"},
    {show : true, code : "RUB",	label : "CURRENCY.RUB", symbol : ""},
    {show : true, code : "RUR",	label : "CURRENCY.RUR", symbol : ""},
    {show : true, code : "RWF",	label : "CURRENCY.RWF", symbol : "₣"},
    {show : true, code : "SAR",	label : "CURRENCY.SAR", symbol : "ر.س"},
    {show : true, code : "SBD",	label : "CURRENCY.SBD", symbol : "$"},
    {show : true, code : "SCR",	label : "CURRENCY.SCR", symbol : ""},
    {show : true, code : "SDD",	label : "CURRENCY.SDD", symbol : ""},
    {show : true, code : "SDG",	label : "CURRENCY.SDG", symbol : "£"},
    {show : true, code : "SDP",	label : "CURRENCY.SDP", symbol : ""},
    {show : true, code : "SEK",	label : "CURRENCY.SEK", symbol : "kr"},
    {show : true, code : "SGD",	label : "CURRENCY.SGD", symbol : ""},
    {show : true, code : "SHP",	label : "CURRENCY.SHP", symbol : "£"},
    {show : true, code : "SIT",	label : "CURRENCY.SIT", symbol : ""},
    {show : true, code : "SKK",	label : "CURRENCY.SKK", symbol : ""},
    {show : true, code : "SLL",	label : "CURRENCY.SLL", symbol : "Le"},
    {show : true, code : "SOS",	label : "CURRENCY.SOS", symbol : "Sh"},
    {show : true, code : "SRD",	label : "CURRENCY.SRD", symbol : "$"},
    {show : true, code : "SRG",	label : "CURRENCY.SRG", symbol : ""},
    {show : true, code : "SSP",	label : "CURRENCY.SSP", symbol : ""},
    {show : true, code : "STD",	label : "CURRENCY.STD", symbol : ""},
    {show : true, code : "STN",	label : "CURRENCY.STN", symbol : "Db"},
    {show : true, code : "SUR",	label : "CURRENCY.SUR", symbol : ""},
    {show : true, code : "SVC",	label : "CURRENCY.SVC", symbol : "₡"},
    {show : true, code : "SYP",	label : "CURRENCY.SYP", symbol : ""},
    {show : true, code : "SZL",	label : "CURRENCY.SZL", symbol : "L"},
    {show : true, code : "THB",	label : "CURRENCY.THB", symbol : "฿"},
    {show : true, code : "TJR",	label : "CURRENCY.TJR", symbol : ""},
    {show : true, code : "TJS",	label : "CURRENCY.TJS", symbol : "ЅМ"},
    {show : true, code : "TMM",	label : "CURRENCY.TMM", symbol : ""},
    {show : true, code : "TMT",	label : "CURRENCY.TMT", symbol : "m"},
    {show : true, code : "TND",	label : "CURRENCY.TND", symbol : "د.ت"},
    {show : true, code : "TOP",	label : "CURRENCY.TOP", symbol : "T$"},
    {show : true, code : "TPE",	label : "CURRENCY.TPE", symbol : ""},
    {show : true, code : "TRL",	label : "CURRENCY.TRL", symbol : ""},
    {show : true, code : "TRY",	label : "CURRENCY.TRY", symbol : ""},
    {show : true, code : "TTD",	label : "CURRENCY.TTD", symbol : "$"},
    {show : true, code : "TWD",	label : "CURRENCY.TWD", symbol : "NT$"},
    {show : true, code : "TZS",	label : "CURRENCY.TZS", symbol : "Sh"},
    {show : true, code : "UAH",	label : "CURRENCY.UAH", symbol : "₴"},
    {show : true, code : "UAK",	label : "CURRENCY.UAK", symbol : ""},
    {show : true, code : "UGS",	label : "CURRENCY.UGS", symbol : ""},
    {show : true, code : "UGX",	label : "CURRENCY.UGX", symbol : "Sh"},
    {show : true, code : "USD",	label : "CURRENCY.USD", symbol : "$"},
    {show : true, code : "USN",	label : "CURRENCY.USN", symbol : ""},
    {show : true, code : "USS",	label : "CURRENCY.USS", symbol : ""},
    {show : true, code : "UYI",	label : "CURRENCY.UYI", symbol : ""},
    {show : true, code : "UYP",	label : "CURRENCY.UYP", symbol : ""},
    {show : true, code : "UYU",	label : "CURRENCY.UYU", symbol : "$"},
    {show : true, code : "UYW",	label : "CURRENCY.UYW", symbol : ""},
    {show : true, code : "UZS",	label : "CURRENCY.UZS", symbol : ""},
    {show : true, code : "VEB",	label : "CURRENCY.VEB", symbol : ""},
    {show : true, code : "VEF",	label : "CURRENCY.VEF", symbol : ""},
    {show : true, code : "VES",	label : "CURRENCY.VES", symbol : ""},
    {show : true, code : "VND",	label : "CURRENCY.VND", symbol : "₫"},
    {show : true, code : "VNN",	label : "CURRENCY.VNN", symbol : ""},
    {show : true, code : "VUV",	label : "CURRENCY.VUV", symbol : "Vt"},
    {show : true, code : "WST",	label : "CURRENCY.WST", symbol : ""},
    {show : true, code : "XAF",	label : "CURRENCY.XAF", symbol : "₣"},
    {show : true, code : "XAG",	label : "CURRENCY.XAG", symbol : ""},
    {show : true, code : "XAU",	label : "CURRENCY.XAU", symbol : ""},
    {show : true, code : "XBA",	label : "CURRENCY.XBA", symbol : ""},
    {show : true, code : "XBB",	label : "CURRENCY.XBB", symbol : ""},
    {show : true, code : "XBC",	label : "CURRENCY.XBC", symbol : ""},
    {show : true, code : "XBD",	label : "CURRENCY.XBD", symbol : ""},
    {show : true, code : "XCD",	label : "CURRENCY.XCD", symbol : "$"},
    {show : true, code : "XDR",	label : "CURRENCY.XDR", symbol : ""},
    {show : true, code : "XEU",	label : "CURRENCY.XEU", symbol : ""},
    {show : true, code : "XFO",	label : "CURRENCY.XFO", symbol : ""},
    {show : true, code : "XFU",	label : "CURRENCY.XFU", symbol : ""},
    {show : true, code : "XOF",	label : "CURRENCY.XOF", symbol : ""},
    {show : true, code : "XPD",	label : "CURRENCY.XPD", symbol : ""},
    {show : true, code : "XPF",	label : "CURRENCY.XPF", symbol : "₣"},
    {show : true, code : "XPT",	label : "CURRENCY.XPT", symbol : ""},
    {show : true, code : "XRE",	label : "CURRENCY.XRE", symbol : ""},
    {show : true, code : "XSU",	label : "CURRENCY.XSU", symbol : ""},
    {show : true, code : "XTS",	label : "CURRENCY.XTS", symbol : ""},
    {show : true, code : "XUA",	label : "CURRENCY.XUA", symbol : ""},
    {show : true, code : "XXX",	label : "CURRENCY.XXX", symbol : ""},
    {show : true, code : "YDD",	label : "CURRENCY.YDD", symbol : ""},
    {show : true, code : "YER",	label : "CURRENCY.YER", symbol : "﷼"},
    {show : true, code : "YUD",	label : "CURRENCY.YUD", symbol : ""},
    {show : true, code : "YUM",	label : "CURRENCY.YUM", symbol : ""},
    {show : true, code : "YUN",	label : "CURRENCY.YUN", symbol : ""},
    {show : true, code : "YUR",	label : "CURRENCY.YUR", symbol : ""},
    {show : true, code : "ZAL",	label : "CURRENCY.ZAL", symbol : ""},
    {show : true, code : "ZAR",	label : "CURRENCY.ZAR", symbol : "R"},
    {show : true, code : "ZMK",	label : "CURRENCY.ZMK", symbol : ""},
    {show : true, code : "ZMW",	label : "CURRENCY.ZMW", symbol : "ZK"},
    {show : true, code : "ZRN",	label : "CURRENCY.ZRN", symbol : ""},
    {show : true, code : "ZRZ",	label : "CURRENCY.ZRZ", symbol : ""},
    {show : true, code : "ZWD",	label : "CURRENCY.ZWD", symbol : ""},
    {show : true, code : "ZWL",	label : "CURRENCY.ZWL", symbol : "$"},
    {show : true, code : "ZWR",	label : "CURRENCY.ZWR", symbol : ""},
]; */


